import Layout from "../components/layout";
import Job from "../Entities/Job";
import HomeSection from "../components/homeSection";
import PromotedJobsListView from "../components/Jobs/promotedJobsListView";
import HomeScroll from "../components/homeScroll";
import { navigate } from 'gatsby';
import { useEffect, useState } from "react";
import { collection, getDocs, getFirestore, where, query } from "firebase/firestore"; 
import firebaseApp from "../utils/firebaseApp";

export type IndexProps = {
  serverData: {
    jobs?: Array<Job>
  }
}

const Index = () => {
  const [jobs, setJobs] = useState<Array<Job>>([{
    jobId: "1234",
    jobTitle: ""
  }]);
  useEffect(() => {
    async function getData() {
      const db = getFirestore(firebaseApp);
      const jobCollection = collection(db, 'jobs');
      const now = new Date();
      const nowInSeconds = Math.floor(now.getTime()/1000);
      const q = query(jobCollection, where("promoted", "==", true), where("createDateSeconds", "<=", nowInSeconds));
      const jobList: Array<Job> = [];
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const jobData = doc.data();
        if (jobData["expirationDateSeconds"] >= nowInSeconds) {
          const job: Job = { ...jobData, jobTitle: jobData.jobTitle, jobId: jobData.jobId };
          jobList.push(job);
        }
      });
      setJobs(jobList);
    }
    getData();
  }, []);
  const postJobClick = () => {
    navigate('/post');
  };
  return (
    <Layout description="RemoteInclusive - Inclusive, Remote and Transparent Job Listings - We're tearing down the walls that keep candidates from applying to your open positions. - Pay Ranges and DEI Policy Required" pageTitle="RemoteInclusive - Inclusive, Remote and Transparent Job Listings">
      <div className="index-container">
        <HomeSection />
        <PromotedJobsListView jobs={jobs} />
        <HomeScroll onPostJob={postJobClick} />
      </div>
    </Layout>
  )
};

export default Index;
