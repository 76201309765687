import AbstractLogoBg from './remoteinclusive_backsplash.avif';
import RemoteWorker from './remote_work_pixelated.avif';
import AbstractLogoBgOrig from './remoteinclusive_backsplash.jpg';
import RemoteWorkerOrig from './remote_work_pixelated.png';
import SvgText, { TextBackground } from './SvgText/svgText';
import useScroll from "../utils/scrollHook";
import { useEffect, useState } from 'react';
import './homeSection.scss';

const HomeSection = () => {    
    const {scrollY} = useScroll();
    const [scrollDown, setScrollDown] = useState(0);
    useEffect(() => {
        setScrollDown(Math.floor(scrollY / 4));
    }, [scrollY]);
    return (
        <section className="home-section">
            <div style={{transform: `translateY(${scrollDown}px)`}} className="home-section__color-bg-pink"></div>
            <div style={{transform: `translateY(-${scrollDown}px)`}} className="home-section__color-bg-blue"></div>
            <div className="home-section__container">
                <div className="home-section__verbiage">
                    <span className="home-section__verbiage-title">
                        Be a Part of a
                        <SvgText className="home-section__title" textBackground={TextBackground.AbstractPink} id="inclusive">
                            <tspan x="0" dy="1em">Remote & Inclusive</tspan>
                        </SvgText>
                        Workforce!<br />
                    </span>
                    <span className="home-section__verbiage-subtitle">
                        We ask the tough questions and require answers. Saving you from having to ask them in a call or interview.
                    </span>
                </div>
                <div className="home-section__logo-bg">
                    <picture>
                        <source srcSet={AbstractLogoBg} type="image/avif" />
                        <img src={AbstractLogoBgOrig} className="home-section__bg" alt="Abstract Background" />
                    
                    </picture>
                    <picture>
                        <source srcSet={RemoteWorker} type="image/avif" />
                        <img  src={RemoteWorkerOrig} alt="Remote Worker" className="home-section__fg" />
                    </picture>
                </div>
            </div>
        </section>
    );
};

export default HomeSection;