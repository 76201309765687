import Job from "../../Entities/Job";
import JobListItem from "./jobListItem";
import { Link } from 'gatsby';

import './promotedJobsListView.scss'

export type PromotedJobsListViewProps = {
  jobs?: Array<Job>
}

const PromotedJobsListView = ({ jobs }: PromotedJobsListViewProps) => {
  return (
    <section className="promoted-jobs-list">
      <span className="promoted-jobs-title">Checkout our promoted jobs or <Link to="/search">Search</Link></span>
      {jobs && jobs.length > 0 && jobs.map((job) => {
        return (<JobListItem job={job} key={`job-${job.jobId}`} />)
      })}
    </section>
  )
};

export default PromotedJobsListView;
