import { useState, useEffect, useCallback, useRef } from 'react'

export const useScroll = () => {

  if (typeof window === "undefined" || !window.document) {
    return {
      scrollY: 0,
      scrollX: 0,
      scrollDirection: '',
    }
  }

  const [state, setState] = useState({
    lastScrollTop: 0,
    bodyOffset: document.body.getBoundingClientRect(),
    scrollY: document.body.getBoundingClientRect().top,
    scrollX: document.body.getBoundingClientRect().left,
    scrollDirection: '', // down, up
  });

  const requestRef = useRef();
  const [ticking, setTicking] = useState(false);

  const handleScrollEvent = useCallback((boundingRect) => {
    setState((prevState) => {
      const prevLastScrollTop = prevState.lastScrollTop
      setTicking(false);
      return {
        setBodyOffset: boundingRect,
        scrollY: -boundingRect.top,
        scrollX: boundingRect.left,
        scrollDirection: prevLastScrollTop > -boundingRect.top ? 'up' : 'down',
        lastScrollTop: -boundingRect.top,
      }
    })
  }, [])

  const animatedScrollEvent = useCallback((e) => {
    if (!ticking) {
      const boundingRect = document.body.getBoundingClientRect();
      setTicking(true);
      requestRef.current = requestAnimationFrame(() => handleScrollEvent(boundingRect));      
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', animatedScrollEvent)

    return () => {
      window.removeEventListener('scroll', animatedScrollEvent);
      cancelAnimationFrame(requestRef.current);
    }
  }, [])

  return {
    scrollY: state.scrollY,
    scrollX: state.scrollX,
    scrollDirection: state.scrollDirection,
  }
}

export default useScroll;